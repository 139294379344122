import GamePackageBaseController from './game_package_base_controller'
import Rails from 'rails-ujs'
import UIkit from 'uikit'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import _ from 'underscore'

export default class extends GamePackageBaseController {
  connect() {
    super.connect()
    this.subscribeToEvents()
  }

  subscribeToEvents() {
    this.listenToPageLeave()

    this.fetchPackages()
    this.loadCampaignSummary()

    Rails.delegate(
      this.element,
      'div.campaign-packages',
      'package-booking:updated',
      () => {
        this.onPackageBookingUpdatedEvent(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="package-order.buyForm"]',
      'ajax:success',
      () => {
        this.onPackageBookingUpdatedEvent(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="package-bid-form.form"]',
      'ajax:success',
      () => {
        this.onPackageBookingUpdatedEvent(event.detail[0])
      }
    )

    $('body').bind('modal-open:createCampaignModal', () =>
      this.stopListenToPageLeave()
    )

    $('body').bind('modal-open:createCampaignModalCheckout', () =>
      this.stopListenToPageLeave()
    )

    $('body').bind('campaign-creation:cancelled', () =>
      this.listenToPageLeave()
    )

    super.renderStickySummaryState()
  }

  fetchPackages() {
    if (this.hasCampaignIdTarget) {
      Ajax.get(
        `/campaigns/${this.campaignIdTarget.value}/packages_list`,
        (response) => {
          document.getElementById('led-package-list').innerHTML = response.ledPackage
          document.getElementById('virtual-package-list').innerHTML = response.virtualPackage
          document.getElementById('packagesWithFans').innerHTML =
            response.packagesWithFans
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
  }

  onPackageBookingUpdatedEvent(event) {
    this.totalBookedPackages = event.totalPackages
    if (this.totalBookingPriceTarget) {
      Dom.html(this.totalBookingPriceTarget, event.totalBookingPrice)
    } else {
      Dom.html(this.continueAction, event.buttonText)
    }
    if (this.packageOrderListTarget) {
      Dom.html(this.packageOrderListTarget, event.packageOrderList)
    }
    if (this.packageBidListTarget) {
      Dom.html(this.packageBidListTarget, event.packageBidList)
    }

    if (this.hasOrderListBoxTarget) {
      _.each(this.orderBoxListItems, (listItem) => {
        if (
          Dom.hasClass(listItem, 'bids') &&
          event.packageOrderType == 'PackageBid'
        ) {
          Dom.addClass(listItem, 'uk-active')
        } else if (
          Dom.hasClass(listItem, 'orders') &&
          event.packageOrderType == 'PackageOrder'
        ) {
          Dom.addClass(listItem, 'uk-active')
        } else {
          Dom.removeClass(listItem, 'uk-active')
        }
      })
    }

    super.renderStickySummaryState()
  }

  suggestSavingDefaultCampaign() {
    this.discardDefaultCampaignGamesConfirmation = false

    let confirmMsgBody =
      '<button  class="uk-modal-close-default" type="button" uk-close></button>' +
      '<p><b>Review changes</b></p><p>You have selected packages. Do you want to create a campaign and save them?</p>'

    var dialogOpt = {
      labels: { ok: 'Create campaign', cancel: 'Discard packages' },
      center: true,
    }

    UIkit.modal
      .confirm(confirmMsgBody, dialogOpt)
      .then(
        $.proxy(this.onSaveCampaignDialogConfirm, this),
        $.proxy(this.onSaveCampaignDialogClose, this)
      )

    $('.uk-modal-dialog button.uk-modal-close').click(
      $.proxy(this.onDiscardDefaultCampaignGames, this)
    )
  }

  submitCampaign() {
    super.submitCampaign()
  }
}
