import FormSubmissionController from './form_submission_controller'
import Dom from '../dom/manipulator'
import { _ } from 'underscore'
import Ajax from '../http/ajax'

const pricing = {
  fixed: 'fixed',
  bid: 'bid',
  both: 'both',
}

export default class extends FormSubmissionController {
  static targets = [
    'bulkSize',
    'bulkSizeDescription',
    'minutes',
    'bidInput',
    'fixedPriceInput',
    'feedSelect',
    'gameIdInput',
    'pricePerUnit',
    'bidPrice',
    'form',
    'package',
    'team',
    'rotationImg',
    'rotationSpinner',
    'rotations',
    'exposureTypeText',
    'teamGames',
  ]

  initialize() {
    this.rotationChanged()
  }

  submit() {}

  pricingChanged(event) {
    let bidInput = this.bidInputTarget
    let fixedInput = this.fixedPriceInputTarget

    if (event.currentTarget.value == pricing.fixed) {
      Dom.hide(bidInput)
      Dom.show(fixedInput)
    } else if (event.currentTarget.value == pricing.bid) {
      Dom.show(bidInput)
      Dom.hide(fixedInput)
    } else {
      Dom.show(bidInput)
      Dom.show(fixedInput)
    }
  }

  unitBulkSizeChanged() {
    const bulkSize = parseInt(this.bulkSizeTarget.value)
    if (bulkSize <= 0 || isNaN(bulkSize)) {
      return
    }

    let minutesInputOptions = this.minutesTarget.options

    // keep first item
    for (let index = minutesInputOptions.length - 1; index > -1; index--) {
      minutesInputOptions.remove(index)
    }
    let start = bulkSize
    while (start <= 40) {
      minutesInputOptions.add(
        new Option(
          (start / bulkSize).toString(),
          (start / bulkSize).toString(),
          false
        )
      )
      start += bulkSize
    }

    _.each(this.bulkSizeDescriptionTargets, (descTarget) => {
      descTarget.innerHTML = `(${bulkSize}min per game)`
    })
    this.updatePriceRecommendation()
  }

  onFeedSelected(event) {
    let feedTarget = event.currentTarget
      .closest('.team-games-list')
      .querySelector('.selected-feed')
    Dom.html(feedTarget, event.currentTarget.selectedOptions[0].innerHTML)
    this.updatePriceRecommendation()
  }

  updatePriceRecommendation() {
    const bulkSize = parseInt(this.bulkSizeTarget.value)
    if (!_.isFinite(bulkSize)) {
      return
    }

    const gameIds = _.map(this.gameIdInputTargets, (gameId) => {
      return gameId.value
    })
    if (_.isUndefined(gameIds) || _.isEmpty(gameIds)) {
      return
    }

    const selectedFeedOption = _.find(this.feedSelectTarget.options, 'selected')
    if (_.isUndefined(selectedFeedOption)) {
      return
    }
    const feedId = selectedFeedOption.value
    if (_.isUndefined(feedId) || _.isEmpty(feedId)) {
      return
    }

    let data = new FormData()
    data.append('packages[bulk_size]', bulkSize)
    _.each(this.teamGamesTargets, (teamGame) => {
      let teamId = teamGame.querySelector('.team-name').dataset.teamId
      data.append(
        `packages[games_by_teams][${teamId}][feed_id]`,
        teamGame.querySelector('.feed').value
      )
      data.append(
        `packages[games_by_teams][${teamId}][game_ids]`,
        _.map(teamGame.querySelectorAll('.game-input'), (game) => {
          return game.value
        })
      )
    })

    Ajax.post('/packages/price_recommendation', data, (response) => {
      this.pricePerUnitTarget.value = response.price
      this.bidPriceTarget.value = response.price / 2
    })
  }

  rotationChanged() {
    let _this = this
    Dom.show(_this.rotationSpinnerTarget)
    Ajax.get(
      `/game_assets/rotation_image?rotation=${_this.rotationsTarget.value}&controller_name=${_this.identifier}`,
      (response) => {
        Dom.html(_this.rotationImgTarget, response.html)
        Dom.html(_this.exposureTypeTextTarget, _this.getNameWithGroup())
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getNameWithGroup() {
    var selectedIndex = this.rotationsTarget.selectedIndex
    let selectedLabel = this.rotationsTarget.options[selectedIndex].text
    let groupName = this.rotationsTarget.options[selectedIndex].closest(
      'optgroup'
    ).label
    return `${groupName} - ${selectedLabel}`
  }
}
