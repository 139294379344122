import { Controller } from 'stimulus'
import DomManipulator from '../dom/manipulator'
import Ajax from '../http/ajax'

export default class extends Controller {
  static targets =
    [
      'displayForm',
      'viewerShip',
      'selectCountry',
      'addbutton',
      'orderId',
      'showStats',
      'remove',
      'hiddenStat',
      'hiddenButton',
      'file',
      'statsDiv',
      'trashGame',
      'trashPackage',
      'showUpdateForm',
      'updateVeiwership',
      'updateButton'
    ]
  connect() {
    $('#uploadPhotos' + this.data.get('game-order-id')).bind(
      'direct-uploads:end',
      $.proxy(this.onPhotosUploaded, this)
    )
  }

  onPhotosUploaded() {
    Turbolinks.clearCache()
    DomManipulator.reloadWithTurbolinks()
  }
  showForm() {
    this.displayFormTarget.hidden = false;
  }
  hiddenForm() {
    this.displayFormTarget.hidden = true
  }
  unableButton() {
    var number = /^[0-9]+$/
    if (this.viewerShipTarget.value != "" && this.selectCountryTarget.value != "" && this.viewerShipTarget.value.match(number)) {
      this.addbuttonTarget.classList.remove('uk-disabled')
    }
    else {
      this.addbuttonTarget.classList.add('uk-disabled')
    }
  }
  saveGameStats() {
    Ajax.get(
      '/seller_dashboard/game_proof_deliveries?country=' + this.selectCountryTarget.value + '&viewership=' + this.viewerShipTarget.value + "&game_order_id=" + this.orderIdTarget.value,
      success => {
        this.showStatsTarget.innerHTML = success.html
      },
      err => {
        console.log('error', err)
      }
    )
  }
  savePackageStats() {

    Ajax.get(
      '/seller_dashboard/package_proof_deliveries?country=' + this.selectCountryTarget.value + '&viewership=' + this.viewerShipTarget.value + "&package_order_id=" + this.orderIdTarget.value,
      success => {
        this.showStatsTarget.innerHTML = success.html
        this.displayFormTarget.hidden = true

      },
      err => {
        console.log('error', err)
      }
    )
  }
  removeFiles() {

    var id = parseInt(this.removeTarget.id)
    Ajax.get(
      '/seller_game_orders/' + id + '/remove_verification_photos',
      success => {
        this.showStatsTarget.innerHTML = success.html

      },
      err => {
      }
    )
  }
  removePackageFiles()
  {
    var id = parseInt(this.removeTarget.id)
    Ajax.get(
      '/seller_package_orders/' + id + '/remove_verification_photos',
      success => {
        this.showStatsTarget.innerHTML = success.html
      },
      err => {
      }
    )
  }
  displayForm(event) {
    event.path[4].querySelectorAll('.uk-flex')[1].classList.remove('uk-hidden')
    event.path[4].querySelectorAll('.uk-flex')[0].classList.add('uk-hidden')
  }
  UpdateGameForm(event) {
    var veiwership = event.path[3].querySelector('input').value
    var id = event.path[3].querySelector('input').id
    var proof_of_delivery_id = event.path[3].querySelector('button').id
    Ajax.get(
      '/seller_game_orders/' + id + '/update_game?proof_of_delivery_id='+ proof_of_delivery_id +'&updated_veiwership=' + veiwership,
      success => {
        this.showUpdateFormTarget.classList.add('uk-hidden')
        this.hiddenStatTarget.classList.remove('uk-hidden')
        this.showStatsTarget.innerHTML = success.html
      },
      err => {
      }
    )
  }
  UpdatePackageForm(event) {
    var veiwership = event.path[3].querySelector('input').value
    var id = event.path[3].querySelector('input').id
    var proof_of_delivery_id = event.path[3].querySelector('button').id
    Ajax.get(
      '/seller_package_orders/' + id + '/update_package?proof_of_delivery_id='+ proof_of_delivery_id +'&updated_veiwership=' + veiwership,
      success => {
        this.showUpdateFormTarget.classList.add('uk-hidden')
        this.hiddenStatTarget.classList.remove('uk-hidden')
        this.showStatsTarget.innerHTML = success.html
      },
      err => {
      }
    )
  }
  cancelForm(event)
  {
    event.path[3].querySelectorAll('.uk-flex')[0].classList.remove('uk-hidden')
    event.path[3].querySelectorAll('.uk-flex')[1].classList.add('uk-hidden')
  }

  deleteGame(event)
  {
    var gameOrderId = event.currentTarget.id
    var id = parseInt(event.currentTarget.getAttribute('value'))
    Ajax.get(
      '/seller_game_orders/' + id + '/remove_game?proof_of_delivery_id='+ gameOrderId,
      success => {
        this.showStatsTarget.innerHTML = success.html
      },
      err => {
      }
    )
  }
  deletePackage(event)
  {

    var packageOrderId = event.currentTarget.id
    var id = parseInt(event.currentTarget.getAttribute('value'))
    Ajax.get(
      '/seller_package_orders/' + id + '/remove_package?proof_of_delivery_id='+ packageOrderId,
      success => {
        this.showStatsTarget.innerHTML = success.html
      },
      err => {
      }
    )
  }
}
