/* eslint-disable indent */
import {Controller} from  'stimulus'
import Ajax from '../http/ajax'

export default class extends Controller {
	
	static targets = [
		'invities',
		'popInvitedPackage',
		'message'
		]

	sendPackage()
	{
		var list = this.invitiesTarget.querySelectorAll('input[type="checkbox"]:checked');
		if (list.length >0)
		{
			var data_list= []
			var package_id = list[0].value
			var url = "/packages/invited_package?package_id="+ package_id
			for(var i=0;i<list.length;i++)
			{
				data_list.push(list[i].id)
			}
			Ajax.post(
				url,
				data_list,
				success => {
					this.messageTarget.classList.add('uk-hidden')
					this.popInvitedPackageTarget.innerHTML = success.html
				},
				err => {
				}
			)
		}
		else
		{
			this.messageTarget.classList.remove('uk-hidden')
		}
		
	}

}