import { Controller } from 'stimulus'
import _ from 'underscore'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [
    'modalContainer',
    'modal',
    'paymentMethod',
    'bulkSize',
    'submitButton',
    'minutes',
    'minimumMinutes',
    'form',
  ]

  submit(event) {
    Dom.show(this.spinner)
    let el = event.currentTarget
    let url = `/game_assets/${el.getAttribute('data-asset-id')}/activate`
    let data = new FormData(this.formTarget)
    Ajax.post(
      url,
      data,
      (response) => {
        let assetsList = document.querySelector('#assets-list')
        let modals = document.querySelectorAll('#activate-unlisted-modal')
        Dom.html(assetsList, response.html)
        _.each(modals, (model) => {
          UIkit.modal(model).hide()
        })
        Dom.hide(this.spinner)
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  getUnlistedGameActivationModal(event) {
    Dom.show(this.spinner)
    let el = event.currentTarget
    let url = `/game_assets/${el.getAttribute(
      'data-asset-id'
    )}/unlisted_activation`
    Ajax.get(
      url,
      (response) => {
        Dom.html(this.modalContainerTarget, response.html)
        UIkit.modal(this.modalTarget).show()
        Dom.hide(this.spinner)
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  bulkSizeChanged(event) {
    const bulkSize = parseInt(event.currentTarget.value)
    const elements = document.querySelectorAll('.min-text')
    _.each(elements, (element) => {
      Dom.html(element, `(${bulkSize} min.)`)
    })

    let minutesInputOptions = this.minutesTarget.options
    let minMinutesOptions = this.minimumMinutesTarget.options

    this.updateMinutesOptions(bulkSize, minutesInputOptions)
    this.updateMinutesOptions(bulkSize, minMinutesOptions)

    this.selectFirstIndex(this.minutesTarget)
    this.selectFirstIndex(this.minimumMinutesTarget)

    bulkSize > 1
      ? Dom.disable(this.minimumMinutesTarget)
      : Dom.enable(this.minimumMinutesTarget)

    this.handlePriceElements()
  }

  minimumMinutesChanged() {
    const minimumMinutes = parseInt(this.minimumMinutesTarget.value)

    let optionList = this.minutesTarget.options
    let firstOption = optionList[optionList.selectedIndex]
    let minutesDifference = minimumMinutes - firstOption.value
    let bulkSize = this.bulkSizeTarget.value

    if (minutesDifference > 0) {
      _.each(optionList, (option) => {
        if (option.value < minimumMinutes) {
          Dom.hide(option)
        }
      })
    } else {
      _.each(optionList, (option) => {
        if (option.value >= minimumMinutes && option.value % bulkSize == 0) {
          Dom.show(option)
        }
      })
    }

    this.selectFirstIndex(this.minutesTarget)
  }

  toggleLock(event) {
    Dom.show(this.spinner)
    let el = event.currentTarget
    let url = `/game_assets/${el.getAttribute('data-asset-id')}/toggle_lock`

    Ajax.get(
      url,
      (response) => {
        Dom.replace(el, response.html)
        Dom.hide(this.spinner)
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  selectFirstIndex(optionList) {
    optionList.selectedIndex = optionList.querySelector(':not([hidden])').index
  }

  updateMinutesOptions(bulkSize, optionList) {
    let start = bulkSize
    _.each(optionList, (list) => {
      if (list.value == start) {
        start += bulkSize
        Dom.show(list)
      } else {
        Dom.hide(list)
      }
    })
  }

  changePaymentMethod() {
    this.handlePriceElements()
    this.handleCreateButtonState()
  }

  handleCreateButtonState() {
    const inputsContainers = document.querySelectorAll('.both:not([hidden])')
    const blankInput = _.find(inputsContainers, (element) => {
      var value = parseInt(element.querySelector('input').value)
      return !value || value < 0
    })
    if (blankInput) {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }
  }

  handlePriceElements() {
    const paymentMethod = this.paymentMethodTarget.value
    const bulkSize = this.bulkSizeTarget.value

    const hiddenElements = document.querySelectorAll(
      `.both:not(.${paymentMethod})`
    )
    const showElements = document.querySelectorAll(`.${paymentMethod}`)
    _.each(hiddenElements, (element) => {
      Dom.hide(element)
      element.querySelector('input').value = ''
    })
    _.each(showElements, (element) => {
      Dom.show(element)
      let input = element.querySelector('input[type="number"]')

      if (input.name.includes('bid')) {
        input.value = input.getAttribute('value')
      } else {
        input.value = input.getAttribute('value') * bulkSize
      }
    })
  }

  get spinner() {
    return document.querySelector('.spinner')
  }
}
