/* eslint-disable indent */
import {Controller} from  'stimulus'
import Ajax from '../http/ajax'

export default class extends Controller {
	static targets = [
		'leagueDropdown',
		'feedDropdown',
		'values',
		'minutesAvailable',
		'averageMinutePrice',
		'averageMinuteSoldPrice',
		'totalRevenue',
		'allFeeds',
		'perFeed',
		'feedsFilter',
		'minutesSold',
		]

	changeFeeds() {
		if (this.leagueDropdownTarget.value != 0) {
			Ajax.get(
				'/seller_dashboard/league_feeds?league_id=' + this.leagueDropdownTarget.value,
				success =>{
					// this.feedDropdownTarget.option = 'All Feed's
					this.feedsFilterTarget.innerHTML = success.html
					this.feedDropdownTarget.removeAttribute("disabled");
				},
				err =>{
					console.log('error',err)
				}
			)
		}
		else {
			this.allFeedsTarget.hidden = false
			this.perFeedTarget.hidden = true
			this.feedDropdownTarget.setAttribute("disabled",true);
			}
	} 

	fetchStats() {
		if (this.feedDropdownTarget.value != 0) {
			this.allFeedsTarget.hidden = true
			this.perFeedTarget.hidden = false

			Ajax.get(
				'/seller_dashboard/feed_stats?broadcast_feed_id=' + this.feedDropdownTarget.value +'&team_id=' + this.feedDropdownTarget.id,
				success =>{
					this.set_stat_feed_value(success)
				},
				err =>{
					console.log('error',err)
				}
			)
		}
		else {
			this.allFeedsTarget.hidden = false
			this.perFeedTarget.hidden = true
		}
	} 

	set_stat_feed_value(success) {
		this.minutesSoldTarget.innerHTML = success.minutes_sold
		this.minutesAvailableTarget.innerHTML = success.minutes_available
		this.averageMinutePriceTarget.innerHTML = success.average_minute_price
		this.averageMinuteSoldPriceTarget.innerHTML = success.average_minute_sold_price
		this.totalRevenueTarget.innerHTML = success.total_revenue
	}
}