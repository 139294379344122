import { Controller } from 'stimulus'
import FormSubmissionObserver from '../form/submissionObserver'
import DomManipulator from '../dom/manipulator'
import Dom from '../dom/manipulator'
import UIkit from 'uikit'
import Ajax from '../http/ajax'
export default class extends Controller {
  static targets = [
    'approveForm',
    'rejectForm',
    'counterBid',
    'counterBidInput',
    'bidRequiredMessage',
    'counterBidPop',
  ]

  connect() {
    if (this.hasApproveFormTarget) {
      // only relevant for pending
      new FormSubmissionObserver().bind(this.approveFormTarget, () => {
        const pendingCountElem = document.getElementById('pendingCount')
        DomManipulator.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
      })
    }

    if (this.hasRejectFormTarget) {
      new FormSubmissionObserver().bind(this.rejectFormTarget, (event) => {
        const pendingCountElem = document.getElementById('pendingCount')
        DomManipulator.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
        DomManipulator.html($('#declinedList'), event.detail[0].rejectedItem)
      })
    }

    if (this.hasCounterBidTarget) {
      new FormSubmissionObserver().bind(this.counterBidTarget, () => {
        const pendingCountElem = document.getElementById('pendingCount')
        Dom.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
      })
    }
    this.hideToolTipCallBack = this.hideToolTip.bind(this)
  }

  conformGame()
  {
    var id = this.approveFormTarget.querySelector('button').id
    var team_id = this.approveFormTarget.querySelectorAll('input')[1].value

    Ajax.get(
      'seller_bids/'+id+'/game_bid_approve?_method=put& team_id='+team_id,
      success =>{
        $('#counterOffer')[0].innerHTML = success.html
        var counter_id = $('#countersCount')[0]
        var counter = parseInt(counter_id.innerText)
        counter_id.innerText = counter -1
      },
      err =>{
        console.log('error',err)
      }
    )
  }
 
  rejectGame()
  {
    var id = this.rejectFormTarget.querySelector('button').id
    var team_id = this.rejectFormTarget.querySelectorAll('input')[1].value

    Ajax.get(
      'seller_bids/'+id+'/game_bid_reject?_method=put& team_id='+team_id,
      success =>{
        $('#counterOffer')[0].innerHTML = success.html
        var counter_id = $('#countersCount')[0]
        var counter = parseInt(counter_id.innerText)
        counter_id.innerText = counter -1
      },
      err =>{
        console.log('error',err)
      }
    )

  }

  showCounterBidForm() {
    Dom.show(this.counterBidPopTarget)
    UIkit.util
      .animate(this.counterBidPopTarget, 'uk-animation-fade')
      .then(() => {
        this.counterBidInputTarget.focus()
        document.body.addEventListener('click', this.hideToolTipCallBack)
      })
  }

  disconnect() {
    document.body.removeEventListener('click', this.hideToolTipCallBack)
  }

  hideToolTip(event) {
    // Hide the tooltip when click outside the tooltip.
    if (
      this.hasCounterBidPopTarget &&
      !event.target.closest('.stads-small-tooltip')
    ) {
      Dom.hide(this.counterBidPopTarget)
      document.body.removeEventListener('click', this.hideToolTipCallBack)
    }
  }
}
