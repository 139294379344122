import { Controller } from 'stimulus'
import Ajax from '../http/ajax'
import Dom from '../dom/manipulator'
import _ from 'underscore'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [
    'detailsForm',
    'contactForm',
    'userForm',
    'spinner',
    'email',
    'phone',
    'vat',
    'country',
    'city',
    'address',
    'zip',
    'vat',
    'subdomainCheck',
    'subdomainError',
    'submitButton',
    'companyDetails',
    'accountToggle',
    'detailsToggle',
    'contactToggle',
    'accountAlert',
    'detailsAlert',
    'contactAlert',
    'avatarIcon',
    'profileImage',
    'profileImageElement',
    'name',
    'subdomain',
    'website',
    'fullName',
  ]

  updateDetails(event) {
    event.preventDefault()
    Dom.show(this.spinnerTarget)

    var formData = new FormData(this.detailsFormTarget)
    var url = '/profile_settings/update_company_details'

    Ajax.post(
      url,
      formData,
      (res) => {
        Dom.html(this.metaTarget(this.nameTarget), res.name)
        Dom.html(this.metaTarget(this.websiteTarget), res.website)
        Dom.html(this.metaTarget(this.subdomainTarget), res.subdomain)


        let imagePath = res[1]
        if (imagePath) {
          this.profileImageTarget.src = res[1]
          Dom.show(this.profileImageTarget)
          Dom.hide(this.avatarIconTarget)
        } else {
          Dom.hide(this.profileImageTarget)
          Dom.show(this.avatarIconTarget)
        }
        this.toggleCard(event, this.detailsToggleTargets)
        Dom.hide(this.spinnerTarget)
        this.hideError(this.detailsAlertTarget)
      },
      (err) => {
        console.log(err)
        Dom.hide(this.spinnerTarget)
        this.showError(this.detailsAlertTarget)
      },
      this
    )
  }

  validateSubdomain() {
    var letters = /^[0-9a-zA-Z]+$/
    if(this.subdomainCheckTarget.value.match(letters)) {
      this.subdomainCheckTarget.value = this.subdomainCheckTarget.value.toLowerCase()
      this.subdomainErrorTarget.hidden = true
      this.submitButtonTarget.disabled = false
    }
    else {
      this.submitButtonTarget.disabled = true
      this.subdomainErrorTarget.hidden = false
    }
  }

  updateContact(event) {
    event.preventDefault()
    Dom.show(this.spinnerTarget)

    var formData = new FormData(this.contactFormTarget)
    var url = '/profile_settings/update_company_contact'

    Ajax.post(
      url,
      formData,
      (res) => {
        Dom.html(this.metaTarget(this.phoneTarget), res.company_contact.phone)
        Dom.html(this.metaTarget(this.countryTarget), res.country_name)
        Dom.html(
          this.metaTarget(this.addressTarget),
          res.company_contact.address1
        )
        Dom.html(this.metaTarget(this.cityTarget), res.company_contact.city)
        Dom.html(this.metaTarget(this.zipTarget), res.company_contact.zip)
        Dom.html(this.metaTarget(this.vatTarget), res.company_contact.vat)
        this.toggleCard(event, this.contactToggleTargets)
        Dom.hide(this.spinnerTarget)
        this.hideError(this.contactAlertTarget)
      },
      (err) => {
        console.log(err)
        Dom.hide(this.spinnerTarget)
        this.showError(this.contactAlertTarget)
      },
      this
    )
  }

  updateUser(event) {
    event.preventDefault()
    Dom.show(this.spinnerTarget)

    var formData = new FormData(this.userFormTarget)
    var url = '/profile_settings/update_user'

    Ajax.post(
      url,
      formData,
      (res) => {

        Dom.html(this.metaTarget(this.emailTarget), res[0].email)
        Dom.html(this.metaTarget(this.fullNameTarget), res[0].full_name)
        this.toggleCard(event, this.accountToggleTargets)
        Dom.hide(this.spinnerTarget)
        this.hideError(this.accountAlertTarget)
      },
      (err) => {
        console.log(err)
        Dom.hide(this.spinnerTarget)
        this.showError(this.accountAlertTarget)
      },
      this
    )
  }

  cancelUpdate(event) {
    let formTarget = event.currentTarget.closest('form')
    let errorTarget = event.currentTarget
      .closest('.form-wrapper')
      .querySelector('.error')
    this.clearFormData(formTarget)
    this.hideError(errorTarget)
  }

  previewProfileImage(event) {
    if (event.currentTarget.files.length > 0) {
      var src = URL.createObjectURL(event.currentTarget.files[0])
      var preview = this.profileImageElementTarget
      Dom.hide(event.currentTarget.closest('div'))
      Dom.show(preview.closest('div.preview-profile-image'))
      preview.src = src
      preview.style.display = 'block'
    }
  }

  removeProfileImage() {
    var imageInput = this.detailsFormTarget.querySelector(
      'input[name="user[profile_attributes][image]"]'
    )
    var preview = this.profileImageElementTarget
    imageInput.value = ''
    Dom.show(imageInput.closest('div'))
    Dom.hide(preview.closest('div.preview-profile-image'))
  }

  toggleCard(event, targets) {
    _.each(targets, (element) => {
      UIkit.toggle(event.currentTarget, {
        target: element,
        animation: 'uk-animation-fade',
      }).toggle()
    })
  }

  clearFormData(form) {
    form.reset()
  }

  hideError(target) {
    Dom.addClass(target, 'uk-hidden')
  }

  showError(target) {
    Dom.removeClass(target, 'uk-hidden')
  }

  textTarget(element) {
    return element.querySelector('.uk-text-medium')
  }

  metaTarget(element) {
    return element.querySelector('.uk-text-small')
  }
}
