import FormSubmissionController from './form_submission_controller'
import DOM from '../dom/manipulator'
import Ajax from '../http/ajax'

export default class extends FormSubmissionController {

  static targets = [
    'slider',
    'sliderValue',
    'meterNeedle', 
    'mask',
    'outlineCurves',
    'low',
    'avg',
    'bavg',
    'aavg',
    'high',
    'outlineEnds',
    'wrapper',
    'bidValue',
  ]

  connect() {
    super.connect()
    this.sliderValueTarget.innerText = this.sliderTarget.value

    /* Set radius for all circles */
    var r = 50
    var circles = document.querySelectorAll('.circle')
    var total_circles = circles.length
    for (var i = 0; i < total_circles; i++) {
      circles[i].setAttribute('r', r)
    }
    
    /* Set meter's wrapper dimension */
    var meter_dimension = (r * 2) + 100
    this.wrapperTarget.style.width = meter_dimension + 'px'
    this.wrapperTarget.style.height = meter_dimension + 'px'
    
    /* Add strokes to circles  */
    this.cf = 2 * Math.PI * r
    this.semi_cf = this.cf / 2
    this.semi_cf_1by5 = this.semi_cf / 5
    this.semi_cf_2by5 = this.semi_cf_1by5 * 2
    this.semi_cf_3by5 = this.semi_cf_1by5 * 3
    this.semi_cf_4by5 = this.semi_cf_1by5 * 4

    this.outlineCurvesTarget.setAttribute('stroke-dasharray', this.semi_cf + ',' + this.cf)
    this.lowTarget.setAttribute('stroke-dasharray', this.semi_cf + ',' + this.cf)
    this.bavgTarget.setAttribute('stroke-dasharray', this.semi_cf_4by5 + ',' + this.cf)
    this.avgTarget.setAttribute('stroke-dasharray', this.semi_cf_3by5 + ',' + this.cf)
    this.aavgTarget.setAttribute('stroke-dasharray', this.semi_cf_2by5 + ',' + this.cf)
    this.highTarget.setAttribute('stroke-dasharray', this.semi_cf_1by5 + ',' + this.cf)
    this.outlineEndsTarget.setAttribute('stroke-dasharray', 0 + ',' + this.semi_cf)
    this.maskTarget.setAttribute('stroke-dasharray', this.semi_cf + ',' + this.cf)
  }

  onSaveSuccess(event) {
    super.onSaveSuccess(event)
    this.onPackageOrderUpdated(event.detail[0])
  }

  onPackageOrderUpdated(event) {
    if (event.packageBuying) {
      DOM.replace(this.packageBuyingSection, event.packageBuying)
    }
  }

  onSliderChange() {
    this.sliderValueTarget.value = this.sliderTarget.value
    this.fillCircle()
  }

  onInputChange() {
    this.sliderTarget.value = this.sliderValueTarget.value == '' ? 0 : this.sliderValueTarget.value
    this.fillCircle()
  }

  fillCircle() {
    Ajax.get(
      '/buyer/get_conversion_rate',
      success =>{

        var value = parseInt(this.sliderValueTarget.value)
        this.bidValueTarget.innerHTML = value * success.conversion_rate
        var percent = ((this.sliderTarget.value - this.sliderTarget.min)/ (this.sliderTarget.max - this.sliderTarget.min)) * 100
        var meter_value = this.semi_cf - ((percent * this.semi_cf) / 100)
        this.maskTarget.setAttribute('stroke-dasharray', meter_value + ',' + this.cf)
        this.meterNeedleTarget.style.transform = 'rotate(' + 
            (270 + ((percent * 180) / 100)) + 'deg)'
      },
      err =>{
        console.log('error',err)
      }
    )
  }

  get packageBuyingSection() {
    return this.targets.find('packageBuying')
  }
}
