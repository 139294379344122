import { Controller } from 'stimulus'
import { _ } from 'underscore'
import Rails from 'rails-ujs'

export default class extends Controller {

  connect() {
    this.initData()

    this.subscribeToEvents()

    this.renderInitialState()
  }


  initData() {
    var modelName = this.data.get('modelName')
    var field = this.data.get('field')

    this.hasMedia = this.data.get('hasMedia') == 'true'
    this.mediaPreview = this.data.get('preview')

    this.mediaInputElementName = modelName + '[' + field + ']'

    var mediaFormId = this.data.get('mediaForm')
    this.mediaFormElement = $('#' + mediaFormId)

    this.onServerSuccessListener = $.proxy(this.onServerSuccessEvent, this)
    this.onServerFailureListener = $.proxy(this.onServerFailureEvent, this)
  }


  subscribeToEvents() {
    $(this.root).bind('dragover', $.proxy(this.onDragOver, this))
    $(this.root).bind('dragleave', $.proxy(this.onDragLeave, this))

    $(this.root).bind('direct-upload:start', $.proxy(this.onDirectUploadStart, this))
    $(this.root).bind('direct-upload:progress', $.proxy(this.onDirectUploadProgression, this))
    $(this.root).bind('direct-upload:error', $.proxy(this.onDirectUploadError, this))
    $(this.mediaFormElement).bind('direct-uploads:end', $.proxy(this.onDirectUploadFinished, this))
  }


  renderInitialState() {
    if (!this.hasMedia) {
      this.renderSelectorState()
    } else {
      this.renderPreviewState(this.mediaPreview)
    }
  }

  beginFileUpload() {
    $(this.root).trigger('media-upload:start')

    this.subscribeToServerResponseEvents()

    this.uploadStartTime = Date.now()

    this.renderUploadState()

    Rails.fire(this.mediaFormElement.get(0), 'submit')
  }


  remove() {
    throw 'Not implemented'
  }


  onDragOver(e) {
    e.preventDefault()
    this.root.classList.add('dragging')
  }

  onDragLeave() {
    this.root.classList.remove('dragging')
  }

  onDrop(e) {
    e.preventDefault()
    this.root.classList.remove('dragging')

    this.fileInput.files = e.originalEvent.dataTransfer.files
    this.fileInput.files = e.originalEvent.dataTransfer.files
  }

  onDirectUploadStart(event) {
    console.log('UPLOAD STARTED')
    console.log(event.originalEvent.detail)

  }

  onDirectUploadProgression(event) {
    console.log('UPLOAD IN PROGRESS')
    console.log(event)

    let data = event.originalEvent.detail

    this.renderProgression(data.progress)
  }

  onDirectUploadError(event) {
    console.log('UPLOAD ERROR')
    console.log(event.originalEvent.detail)

    this.renderSelectorState()

    $(this.root).trigger('media-upload:error')
  }


  onDirectUploadFinished() {
    console.log('UPLOAD FINISHED')
  }


  onServerSuccessEvent(event) {
    this.unsubscribeFromServerResponseEvents()
    const preview = event.originalEvent.detail[0].pageHtml
    this.renderPreviewState(preview)
    $(this.root).trigger({ type: 'media-upload:end', preview: preview })
  }

  onServerFailureEvent() {
    this.unsubscribeFromServerResponseEvents()

    this.renderPreviewFailureState()

    $(this.root).trigger({ type: 'media-upload:end', preview: null })
  }


  subscribeToServerResponseEvents() {
    $(this.mediaFormElement).bind('ajax:success', this.onServerSuccessListener)
    $(this.mediaFormElement).bind('ajax:error', this.onServerFailureListener)
  }

  unsubscribeFromServerResponseEvents() {
    $(this.mediaFormElement).off('ajax:success', this.onServerSuccessListener)
    $(this.mediaFormElement).off('ajax:error', this.onServerFailureListener)
  }

  enableFileSelection() {
    this.fileInput.value = ''
    this.fileInput.disabled = false
  }


  renderSelectorState() {
    $(this.progressState).hide()
    $(this.previewState).hide()
    $(this.previewFailureState).hide()
    $(this.selectorState).show()

    this.enableFileSelection()
  }


  renderUploadState() {
    $(this.selectorState).hide()

    this.renderProgression(0)

    $(this.progressState).show()
  }


  renderProgression(progress) {
    this.renderProgressionState(progress)

    this.progressText.textContent = Math.round(progress)
    this.progressBar.value = progress

    const estimatedTimeRemaining = this.evaluateUploadTimeRemaining(progress)
    this.remainingUploadTime.textContent = ' ' + Math.round(estimatedTimeRemaining) + ' '
  }

  renderProgressionState(progress) {
    if (progress == 0) {
      this.uploadStarted = false
      $(this.uploadProgressText).hide()
      $(this.initiatingUploadText).show()
    } else if (!this.uploadStarted) {
      this.uploadStarted = true
      $(this.initiatingUploadText).hide()
      $(this.uploadProgressText).show()
    }
  }

  renderPreviewState(preview) {
    if (_.isElement(this.preview)) {
      this.preview.innerHTML = preview
    }

    $(this.progressState).hide()
    $(this.previewFailureState).hide()
    $(this.selectorState).hide()

    $(this.previewState).show()
  }

  renderPreviewFailureState() {
    $(this.progressState).hide()
    $(this.previewFailureState).show()
  }


  evaluateUploadTimeRemaining(progress) {
    if (progress == 0) {
      return 9999999
    }

    var secondsPassed = (Date.now() - this.uploadStartTime) / 1000
    return (100 - progress) / progress * (secondsPassed)
  }

  get root() {
    return this.targets.find('root')
  }

  get fileInput() {
    return this.targets.find('fileInput')
  }

  get selectorState() {
    return this.targets.find('selectorState')
  }

  get progressState() {
    return this.targets.find('progressState')
  }

  get previewState() {
    return this.targets.find('previewState')
  }

  get previewFailureState() {
    return this.targets.find('previewFailureState')
  }

  get progressBar() {
    return this.targets.find('progressBar')
  }

  get progressText() {
    return this.targets.find('progressText')
  }

  get preview() {
    return this.targets.find('preview')
  }

  get remainingUploadTime() {
    return this.targets.find('remainingUploadTime')
  }

  get initiatingUploadText() {
    return this.targets.find('initiatingUploadText')
  }

  get uploadProgressText() {
    return this.targets.find('uploadProgressText')
  }

}
