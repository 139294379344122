import { _ } from 'underscore'
import MediaUploadController from './model_media_upload_base_controller'

import Rails from 'rails-ujs'

export default class extends MediaUploadController {
  nullToUndefined(value) {
    if (_.isNull(value)) {
      return undefined
    }
    return value
  }

  initData() {
    super.initData()

    this.modelId = this.data.get('modelId')
    const routeConfiguration = _.defaults(
      {
        basePath: this.nullToUndefined(this.data.get('basePath')),
        addMediaAction: this.nullToUndefined(this.data.get('addMediaAction')),
        updateMediaAction: this.nullToUndefined(
          this.data.get('updateMediaAction')
        ),
        removeMediaAction: this.nullToUndefined(
          this.data.get('removeMediaAction')
        )
      },
      {
        basePath: '/media/',
        addMediaAction: 'add_media',
        updateMediaAction: 'update_media',
        removeMediaAction: 'remove_media'
      }
    )
    this.basePath = routeConfiguration.basePath
    this.formActionBase = this.basePath + this.modelId + '/'
    this.addMediaAction = routeConfiguration.addMediaAction
    this.updateMediaAction = routeConfiguration.updateMediaAction
    this.removeMediaAction = routeConfiguration.removeMediaAction

    this.onMediaRemovedEventListener = $.proxy(
      this.onMediaRemoveSuccessEvent,
      this
    )
    this.onMediaRemovedFailureEventListener = $.proxy(
      this.onMediaRemoveFailureEvent,
      this
    )
  }

  beginFileUpload() {
    $(this.mediaFormElement).attr(
      'action',
      this.formActionBase + this.getFormFileUploadAction()
    )

    super.beginFileUpload()
  }

  getFormFileUploadAction() {
    return this.data.get('hasMedia') === 'false'
      ? this.addMediaAction
      : this.updateMediaAction
  }

  remove() {
    $(this.root).trigger('media-upload:start')

    this.subscribeToMediaRemovalEvents()

    this.disableRemoveMediaButton()

    $(this.mediaFormElement).attr(
      'action',
      this.formActionBase + this.removeMediaAction
    )
    Rails.fire(this.mediaFormElement.get(0), 'submit')
  }

  subscribeToMediaRemovalEvents() {
    $(this.mediaFormElement).bind(
      'ajax:success',
      this.onMediaRemovedEventListener
    )
    $(this.mediaFormElement).bind(
      'ajax:error',
      this.onMediaRemovedFailureEventListener
    )
  }

  enableRemoveMediaButton() {
    $('.remove-media').prop('disabled', false)
    $('.remove-spinner').addClass('uk-hidden')
  }

  disableRemoveMediaButton() {
    $('.remove-media').prop('disabled', true)
    $('.remove-spinner').removeClass('uk-hidden')
  }

  onMediaRemoveSuccessEvent() {
    this.unsubscribeFromMediaRemoveEvents()

    this.renderSelectorState()

    this.enableRemoveMediaButton()

    $(this.root).trigger('media-upload:end')
  }

  onMediaRemoveFailureEvent() {
    this.unsubscribeFromMediaRemoveEvents()

    this.enableRemoveMediaButton()

    //todo: what should I show here? nothing?

    $(this.root).trigger('media-upload:end')
  }

  unsubscribeFromMediaRemoveEvents() {
    $(this.mediaFormElement).off(
      'ajax:success',
      this.onMediaRemovedEventListener
    )
    $(this.mediaFormElement).off(
      'ajax:error',
      this.onMediaRemovedFailureEventListener
    )
  }
}
