import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import _ from 'underscore'

const Page = {
  Games: 'games-page',
  Packages: 'packages-page',
}

export default class extends Controller {
  static targets = [
		'featureIteamSelected',
    'filterName',
		]
  connect() {

    this.featureFilter();    // for popular leagues and feature clubs
    // Get Current filter name
    // Check If we have same filter in params
    var filter = this.element
      .querySelector('[data-type]')
      .getAttribute('data-type')
    if (this.getUrlParameter(filter)) {
      var element = document.querySelectorAll(
        `[data-type="${filter}"][data-id="${this.getUrlParameter(filter)}"]`
      )[0]
      this.checkFilterType(element)
    }
  }
    
  applyFeatureFilter()    
  {
    var params = document.location.href.split('=')
    var team_league_id = params[1]
    for(var i=0;i<this.featureIteamSelectedTarget.getElementsByTagName("li").length;i++)
    {
      if(this.featureIteamSelectedTarget.getElementsByTagName("li")[i].getAttribute('data-id') == team_league_id)
      {  
        var element = this.featureIteamSelectedTarget.getElementsByTagName("li")[i]
        this.featureFilterSelected(element)
        break   
      }
    }
  }

  featureFilter()   
  {
    // does the feature query exist or not 
    if (document.location.href.includes('q'))
    {
      if(this.filterNameTarget.innerText == 'Clubs' && document.location.href.includes('game_home_team_id_in'))
      {
        this.applyFeatureFilter();
      }
      else if (this.filterNameTarget.innerText == 'League' && document.location.href.includes('game_league_id_in') )
      {
        this.applyFeatureFilter()
      }
    }
  }

  featureFilterSelected(element) {
    this.checkFilterType(element)
    this.submit()
  }

  checkFilterType(element) {
    if (this.multiselect == false) {
      this.updateSingleSelectItem(element)
    } else {
      this.updateMultiSelectItem(element)
    }
  }

  // Search in filter item
  filterSearchData() {
    const searchText = this.searchValue.toUpperCase()
    const childs = this.filterListItem.children
    _.each(childs, (child) => {
      var listItem = child.getAttribute('data-value').toUpperCase()
      if (
        listItem.indexOf(searchText) === 0 ||
        listItem.indexOf(' ' + searchText) !== -1 ||
        searchText === ''
      )
        child.style.display = 'list-item'
      else child.style.display = 'none'
    })
  }

  filterItemSelected(event) {
    Dom.show(this.spinner)
    let element = event.currentTarget
    this.checkFilterType(element)
    this.submit()
  }

  updateSingleSelectItem(element) {
    var elements = document.getElementsByName(element.getAttribute('data-type'))
    // If no item select
    if (elements.length == 0) {
      this.addHiddenField(element)
      Dom.addClass(element, 'selected')
      this.updateFilterButton()
      return
    } // If unselect the same item
    if (elements[0].value == element.getAttribute('data-id')) {
      this.unSelectFilterItem()
      this.removeAllHiddenFields(element)
      this.resetFilterButton()
    } else {
      // If one item is already selected and select another item
      this.unSelectFilterItem()
      this.removeAllHiddenFields(element)
      Dom.addClass(element, 'selected')
      this.addHiddenField(element)
      this.updateFilterButton()
    }
  }

  updateMultiSelectItem(element) {
    Dom.toggleClass(element, 'selected')
    if (element.getAttribute('data-seleted') == 'false') {
      this.setTotalSelectedItem(this.totalSelectFilterItem + 1)
      this.setFilterItem(element, 'true')
      this.addHiddenField(element)
    } else {
      this.setTotalSelectedItem(this.totalSelectFilterItem - 1)
      this.setFilterItem(element, 'false')
      this.removeSpecificHiddenField(element)
    }
    if (this.totalSelectFilterItem > 0) {
      this.updateFilterButton()
    } else {
      this.resetFilterButton()
    }
  }

  updateFilterButton() {
    Dom.addClass(this.filterButton, 'item-selected')
    Dom.hide(this.chevronIcon)
    Dom.show(this.closeIcon)
  }

  resetFilterButton() {
    Dom.removeClass(this.filterButton, 'item-selected')
    Dom.show(this.chevronIcon)
    Dom.hide(this.closeIcon)
  }

  clearFilter(event) {
    Dom.show(this.spinner)
    this.resetFilterButton()
    if (this.multiselect) this.setTotalSelectedItem(0)
    this.removeAllHiddenFields(event.currentTarget)
    this.unSelectFilterItem()
    this.submit()
  }

  submit() {
    let formData = new FormData(this.form)
    Ajax.post(
      this.form.action,
      formData,
      (response) => {
        Dom.hide(this.spinner)
        if (this.controllerName == Page.Games) {
          document.getElementById('led-list').innerHTML = response.ledList
          document.getElementById('virtual-list').innerHTML =
            response.virtualList
          document.getElementById('gamesWithFans').innerHTML =
            response.gamesWithFans
        } else {
          document.getElementById('led-package-list').innerHTML = response.ledPackage
          document.getElementById('virtual-package-list').innerHTML =
            response.virtualPackage
          document.getElementById('packagesWithFans').innerHTML =
            response.packagesWithFans
        }
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  removeAllHiddenFields(element) {
    // Remove input hidden fields
    this.elements = document.getElementsByName(
      element.getAttribute('data-type')
    )
    _.each(
      this.elements,
      () => {
        this.form.removeChild(this.elements[0])
      },
      this
    )
  }

  unSelectFilterItem() {
    // Unselect all selected Items
    const childrens = this.filterListItem.querySelectorAll('.selected')
    _.each(childrens, (child) => {
      Dom.removeClass(child, 'selected')
      this.setFilterItem(child, 'false')
    })
  }

  addHiddenField(element) {
    const filterValues = element.getAttribute('data-id').split('|')

    _.each(filterValues, (filterValue) => {
      var input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', element.getAttribute('data-type'))
      input.setAttribute('value', filterValue)
      input.setAttribute('id', this.combinedInputId(element))
      this.form.appendChild(input)
    })
  }

  removeSpecificHiddenField(element) {
    const input = document.getElementById(this.combinedInputId(element))
    this.form.removeChild(input)
  }

  get controllerName() {
    return this.element.getAttribute('data-page')
  }

  setFilterItem(element, value) {
    element.setAttribute('data-seleted', value)
  }

  combinedInputId(element) {
    return element.getAttribute('data-id') + element.getAttribute('data-type')
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => {
      return controller.context.identifier === identifier
    })
  }

  setTotalSelectedItem(count) {
    this.targets
      .find('totalSelectItem')
      .setAttribute('data-total-selected-items', count)
    this.targets.find('totalSelectItem').innerHTML = count + ' selected'
  }

  getUrlParameter(param) {
    var url = window.location.search.substring(1),
      params_list = url.split('&'),
      params_key_value,
      params_value = false

    _.each(params_list, (parameter) => {
      params_key_value = parameter.split('=')
      if (decodeURIComponent(params_key_value[0]) === param) {
        params_value =
          params_key_value[1] === undefined
            ? true
            : decodeURIComponent(params_key_value[1])
        return false
      }
    })
    return params_value
  }

  get totalSelectFilterItem() {
    return parseInt(
      this.targets
        .find('totalSelectItem')
        .getAttribute('data-total-selected-items')
    )
  }

  get filterButton() {
    return this.element.querySelector('button')
  }

  get form() {
    const gamesPage = this.getControllerByIdentifier(
      this.element.getAttribute('data-page')
    )
    return gamesPage.targets.find('form')
  }

  get searchValue() {
    return this.targets.find('searchValue').value
  }

  get filterListItem() {
    return this.targets.find('filterListItem')
  }

  get multiselect() {
    return this.element.getAttribute('data-multiselect') == 'true'
  }

  get spinner() {
    const gamesPage = this.getControllerByIdentifier(
      this.element.getAttribute('data-page')
    )
    return gamesPage.targets.find('spinner')
  }

  get closeIcon() {
    return this.targets.find('close')
  }

  get chevronIcon() {
    return this.targets.find('chevron')
  }

  get selectedFilterName() {
    return this.targets.find('selectedFilterName')
  }
}
