import { Controller } from 'stimulus'
import { _ } from 'underscore'
import Rails from 'rails-ujs'
import UIkit from 'uikit'
import Dom from '../dom/manipulator'

export default class extends Controller {
  static targets = [
    'bulkUnitSize',
    'minutes',
    'buyForm',
    'unitPrice',
    'switcher',
    'total',
    'packageBuying',
    'minutesBooking',
    'plusButton',
  ]
  connect() {
    this.subscribeToFormEvents()

    this.requestConfirmationBeforeZeroMin = !window.location.pathname.includes(
      'games'
    )

    this.debouncedSubmitHandler = _.debounce(() => {
      this.submitBookingForm()
    }, 1000)
  }

  subscribeToFormEvents() {
    this.buyFormTarget.addEventListener('ajax:success', (event) => {
      this.onPackageOrderUpdatedEvent(event.detail[0])
    })
  }

  onPackageOrderUpdatedEvent(event) {
    this.totalTarget.innerHTML = event.packageOrderTotal

    if (event.packageBuying && this.packageBuyingTarget != null) {
      Dom.replace(this.packageBuyingTarget, event.packageBuying)
    }

    this.renderActivationState()

    Turbolinks.clearCache()
  }

  renderActivationState() {
    if (+this.minutesTarget.value === 0) {
      Dom.toggleClass(this.minutesBookingTarget, 'inactive')
    } else if (Dom.hasClass(this.minutesBookingTarget, 'inactive')) {
      Dom.toggleClass(this.minutesBookingTarget, 'inactive')
    }
  }

  submitBookingForm() {
    Rails.fire(this.buyFormTarget, 'submit')
  }

  buyNow(event) {
    this.plusMinute(event)
  }

  plusMinute(event) {
    var updatedMinutesCount = parseInt(this.minutesTarget.value) + 1
    if (updatedMinutesCount <= this.data.get('maxMinutes')) {
      this.requestMinutesBookingUpdateIfPermitted(event, updatedMinutesCount)
    } else {
      if (UIkit.tooltip(event.target).title.length <= 0) {
        UIkit.tooltip(event.target, {
          delay: 0,
          pos: 'top-right',
          title: 'No more bundles available',
        })
        UIkit.tooltip(event.target).show()
      }
      this.stateSwitcher.show(1)
    }
  }

  minusMinute(event) {
    UIkit.tooltip(this.plusButtonTarget, {
      delay: 30000,
      title: 'Press to add another minute',
    })

    var updatedMinutesCount

    updatedMinutesCount = +this.minutesTarget.value - 1

    if (updatedMinutesCount >= 0) {
      this.requestMinutesBookingUpdateIfPermitted(event, updatedMinutesCount)
    }
  }

  requestMinutesBookingUpdateIfPermitted(event, updatedMinutesCount) {
    // if (updatedMinutesCount === 0 && this.requestConfirmationBeforeZeroMin) {
    //   this.executeOnUserConfirmation(event, updatedMinutesCount)
    //   return
    // }

    this.requestMinutesBookingUpdate(event, updatedMinutesCount)
  }

  requestMinutesBookingUpdate(event, updatedMinutesCount) {
    if (updatedMinutesCount === 0) {
      this.stateSwitcher.show(3)
    }
    this.minutesTarget.value = updatedMinutesCount
    this.unitPriceTarget.value =
      updatedMinutesCount * this.data.get('unitPrice')

    this.debouncedSubmitHandler()
    // $(event.target).trigger('requesting-game-minutes-booking')
  }

  get stateSwitcher() {
    return UIkit.switcher(this.switcherTarget)
  }
}
