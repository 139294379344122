import { Controller } from 'stimulus'
import _ from 'underscore'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [
    'dropdown',
    'table',
    'rotationImg',
    'rotations',
    'rotationSpinner',
    'exposureTypeText',
  ]

  connect() {
    this.rotationChanged()
  }

  getModal(event) {
    Dom.show(this.spinner)
    var dropdown = this.dropdownTarget
    if (event.currentTarget.getAttribute('data-url') != 'packages')
      UIkit.dropdown(dropdown).hide()
    Ajax.get(
      `${event.currentTarget.getAttribute(
        'data-url'
      )}/new?team_id=${dropdown.getAttribute('data-teamId')}`,
      (response) => {
        this.showModal(response.html)
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  showModal(html) {
    Dom.html(this.modal, html)
    UIkit.modal(this.modal).show()
    Dom.hide(this.spinner)
  }

  addHover(event) {
    this.hover(event, 'add')
  }

  removeHover(event) {
    this.hover(event, 'remove')
  }

  rotationChanged() {
    let _this = this
    if (this.hasRotationsTarget) {
      Ajax.get(
        `/game_assets/rotation_image?rotation=${_this.rotationsTarget.value}&controller_name=${_this.identifier}`,
        (response) => {
          Dom.html(_this.rotationImgTarget, response.html)
          Dom.html(_this.exposureTypeTextTarget, _this.getNameWithGroup())
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }

  getNameWithGroup() {
    var selectedIndex = this.rotationsTarget.selectedIndex
    let selectedLabel = this.rotationsTarget.options[selectedIndex].text
    let groupName = this.rotationsTarget.options[selectedIndex].closest(
      'optgroup'
    ).label
    return `${groupName} - ${selectedLabel}`
  }

  hover(event, type) {
    var cell = event.target
    var cellIndex = cell.cellIndex
    var rowIndex = cell.parentElement.rowIndex
    var game = this.rowCells(rowIndex)[0]
    // Handle row hover state
    _.times(cellIndex, function () {
      if (type === 'add') {
        Dom.addClass(cell, 'hover')
        Dom.addClass(game, 'hover')
      } else {
        Dom.removeClass(cell, 'hover')
        Dom.removeClass(game, 'hover')
      }
      cell = cell.previousSibling
    })
    // Handle column hover state
    _.times(rowIndex, (row) => {
      var currentCell = this.rowCells(row)[cellIndex]
      if (type === 'add') {
        currentCell.classList.add('hover')
      } else {
        currentCell.classList.remove('hover')
      }
    })
  }

  rowCells(rowIndex) {
    return this.tableTarget.rows[rowIndex].cells
  }

  skipOne(element) {
    return _.rest(element)
  }

  findCheckedCell(cells) {
    return _.find(cells, (cell) => {
      const checkbox = this.checkbox(cell)
      return checkbox.checked && !checkbox.disabled
    })
  }

  findCheckedCells(cells) {
    return _.filter(cells, (cell) => {
      const checkbox = this.checkbox(cell)
      return checkbox.checked && !checkbox.disabled
    })
  }

  findUnheckedRow(rows, cell) {
    return _.find(rows, (row) => {
      return this.checkbox(row.cells[cell.cellIndex]).checked == false
    })
  }

  isCellDisabled(cell) {
    return this.checkbox(cell).disabled
  }

  setCheckbox(cell, value) {
    this.checkbox(cell).checked = value
  }

  checkbox(cell) {
    return cell.querySelector('input[type="checkbox"]')
  }

  get spinner() {
    return document.querySelector('.spinner')
  }
  get modal() {
    return document.querySelector('.uk-modal-full')
  }
}
