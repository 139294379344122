import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 'role', 'subdomain','subdomainfield','subdomainError','submitButton' ]

  showSubdomain() {
    if (this.roleTarget.value == 'team') {
      this.subdomainTarget.hidden = false
    }
    else {
      this.subdomainTarget.hidden = true
    }
  }

  validateSubdomain()
  {
    var letters = /^[0-9a-zA-Z]+$/
    if(this.subdomainfieldTarget.value.match(letters)) {
      this.subdomainfieldTarget.value = this.subdomainfieldTarget.value.toLowerCase()
      this.subdomainErrorTarget.hidden = true
      this.submitButtonTarget.disabled = false
    }
    else {
      if( this.subdomainfieldTarget.value != "")
      {
        this.submitButtonTarget.disabled = true
        this.subdomainErrorTarget.hidden = false
      }
    }

  }

}
