import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import { _ } from 'underscore'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [
    'game',
    'gameModal',
    'gameList',
    'allGames',
    'allTeams',
    'addGameModal',
    'addTeamModal',
    'gameListItem',
    'teamListItem',
    'homeTeamFilter',
    'awayTeamFilter',
    'gameDisplayText',
    'gameIdInput',
    'noGame',
  ]

  changeGame(event) {
    this.showAllGames()
    let currentGameId = parseInt(event.currentTarget.dataset.gameId)
    let gameIds = _.filter(this.gameIds, (id) => {
      return id != currentGameId
    })
    _.each(this.gameListItemTargets, (gameItem) => {
      if (gameIds.includes(parseInt(gameItem.dataset.gameId))) {
        Dom.hide(gameItem)
      }
    })
  }

  changeTeam(event) {
    this.showAllTeams()
    let currentTeamId = parseInt(event.currentTarget.dataset.teamId)
    let teamIds = _.filter(this.teamIds, (id) => {
      return id != currentTeamId
    })
    _.each(this.teamListItemTargets, (teamItem) => {
      if (teamIds.includes(parseInt(teamItem.dataset.teamId))) {
        Dom.hide(teamItem)
      }
    })
  }

  removeGame(event) {
    let teamGamesList = event.currentTarget.closest('.team-games-list')
    let gamesCountEl = teamGamesList.querySelector('.games-count')
    if (this.games.length > 2) {
      let newGamesCount = parseInt(gamesCountEl.innerText) - 1
      if (newGamesCount > 0) {
        Dom.html(gamesCountEl, newGamesCount)
        Dom.remove(this.gameTarget)
      } else {
        Dom.remove(teamGamesList)
      }
    } else {
      UIkit.util.animate(this.gamesAlert, 'uk-animation-fade')
      Dom.show(this.gamesAlert)
    }
  }

  removeTeam(event) {
    let teamGamesList = event.currentTarget.closest('.team-games-list')
    let gamesCountEl = teamGamesList.querySelector('.games-count')
    console.log(gamesCountEl.innerText)
    if (this.games.length - gamesCountEl.innerText > 1) {
      Dom.remove(teamGamesList)
    } else {
      UIkit.util.animate(this.gamesAlert, 'uk-animation-fade')
      Dom.show(this.gamesAlert)
    }
  }

  showAllGames() {
    _.each(this.gameListItemTargets, (gameItem) => {
      Dom.show(gameItem)
    })
  }

  showAllTeams() {
    _.each(this.teamListItemTargets, (teamItem) => {
      Dom.show(teamItem)
    })
  }

  filterGames() {
    const homeTeamFilterText = this.homeTeamFilterTarget.value.toUpperCase()
    const awayTeamFilterText = this.awayTeamFilterTarget.value.toUpperCase()
    const lis = this.gameListTarget.children
    for (var i = 1; i < lis.length; i++) {
      var homeTeam = lis[i].getAttribute('data-home-team').toUpperCase()
      var awayTeam = lis[i].getAttribute('data-away-team').toUpperCase()
      if (
        (awayTeam.indexOf(awayTeamFilterText) === 0 ||
          awayTeamFilterText === '') &&
        (homeTeam.indexOf(homeTeamFilterText) === 0 ||
          homeTeamFilterText === '')
      )
        lis[i].style.display = 'list-item'
      else lis[i].style.display = 'none'
    }
  }

  gameSelected(event) {
    let _this = this
    let el = event.currentTarget
    Ajax.get(
      `/packages/new_game?game_id=${el.dataset.gameId}&controller_name=${_this.identifier}`,
      (response) => {
        this.gameTarget.innerHTML = response.html
      },
      (err) => {
        console.log(err)
      }
    )
  }

  addGame(event) {
    let _this = this
    let el = event.currentTarget

    Ajax.get(
      `/packages/new_game?game_id=${el.dataset.gameId}&controller_name=${_this.identifier}`,
      (response) => {
        Dom.prepend(response.html, _this.allGamesTarget)
      },
      (err) => {
        console.log(err)
      }
    )
    this.updateCount(event)
    UIkit.modal(this.addGameModalTarget).hide()
    Dom.hide(this.gamesAlert)
    if (this.hasNoGameTarget)
      Dom.hide(this.noGameTarget)
  }

  addTeam(event) {
    let _this = this
    let el = event.currentTarget

    Ajax.get(
      `/packages/new_team?team_id=${el.dataset.teamId}&package_id=${el.dataset.packageId}}&controller_name=${_this.identifier}`,
      (response) => {
        Dom.prepend(response.html, _this.allTeamsTarget)
      },
      (err) => {
        console.log(err)
      }
    )
    UIkit.modal(this.addTeamModalTarget).hide()
  }

  updateCount(event) {
    let teamGamesList = event.currentTarget.closest('.team-games-list')
    let gamesCountEl = teamGamesList.querySelector('.games-count')
    let newGamesCount = parseInt(gamesCountEl.innerText) + 1
    Dom.html(gamesCountEl, newGamesCount)
  }

  get games() {
    return document.querySelectorAll('.game')
  }

  get teams() {
    return document.querySelectorAll('.team-name')
  }

  get gameIds() {
    return _.map(this.games, (game) => {
      return parseInt(game.dataset.gameId)
    })
  }

  get teamIds() {
    return _.map(this.teams, (team) => {
      return parseInt(team.dataset.teamId)
    })
  }

  get gamesAlert() {
    return document.querySelector('#games-alert')
  }
}
