import { Controller } from 'stimulus'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = ['form', 'modalContainer', 'modal', 'invitationModal', 'invitationLink', 'copied', 'stateChange']

  getInvitationModal() {
    Ajax.get(
      '/users/invitation/new',
      (response) => {
        Dom.html(this.modalContainerTarget, response.html)
        UIkit.modal(this.modalTarget).show()
      },
      (err) => {
        console.log(err)
      },
      this
    )
  }

  validateFields()
  {
    if (this.formTarget.user_company_name.value != '' && this.formTarget.user_email.value != '')
    {
      this.stateChangeTarget.removeAttribute("disabled");
    }
    else
    {
      this.stateChangeTarget.setAttribute("disabled",true);
    }
  }

  getInvitationLink() {
    var data = new FormData(this.formTarget)
    var url = this.formTarget.action
    Ajax.post(
      url,
      data,
      (response) => {
        Dom.html(this.invitationModalTarget, response.html)
      },
      (err) => {
        console.log(err)
      },
      this
    )
  }

  copyLink() {
    this.invitationLinkTarget.select()
    document.execCommand('copy')
    Dom.removeClass(this.copiedTarget, 'uk-hidden')
  }
}
