import GamePackageBaseController from './game_package_base_controller'
import Rails from 'rails-ujs'
import UIkit from 'uikit'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import _ from 'underscore'

export default class extends GamePackageBaseController {
  connect() {
    super.connect()
    this.subscribeToEvents()
  }

  subscribeToEvents() {
    this.listenToPageLeave()

    this.fetchGames()
    this.loadCampaignSummary()

    Rails.delegate(
      this.element,
      'div.campaign-games',
      'booking:updated',
      () => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="game-order.form"]',
      'ajax:success',
      () => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="bid-form.form"]',
      'ajax:success',
      () => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    $('body').bind('modal-open:createCampaignModal', () =>
      this.stopListenToPageLeave()
    )

    $('body').bind('modal-open:createCampaignModalCheckout', () =>
      this.stopListenToPageLeave()
    )

    $('body').bind('campaign-creation:cancelled', () =>
      this.listenToPageLeave()
    )

    super.renderStickySummaryState()
  }

  fetchGames() {
    var params = document.location.href.includes('q') ? ('?' + document.location.href.split('?')[1]) : ""   
    if (this.hasCampaignIdTarget) {
      Ajax.get(
        "/campaigns/"+this.campaignIdTarget.value+"/games_list" +params,
        (response) => {
          document.getElementById('led-list').innerHTML = response.ledList
          document.getElementById('virtual-list').innerHTML =
            response.virtualList
          document.getElementById('gamesWithFans').innerHTML =
            response.gamesWithFans
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
  }

  onGameBookingUpdatedEvent(event) {
    this.totalBookedMinutes = event.totalMinutes
    this.totalBudget = event.totalBudget

    if (this.hasTotalBookingPriceTarget) {
      Dom.html(this.totalBookingPriceTarget, event.totalBookingPrice)
    } else {
      Dom.html(this.continueAction, event.buttonText)
    }
    if (this.orderList) {
      Dom.html(this.orderList, event.orderList)
    }
    if (this.bidList) {
      Dom.html(this.bidList, event.bidList)
    }

    if (this.hasOrderListBoxTarget) {
      _.each(this.orderBoxListItems, (listItem) => {
        if (Dom.hasClass(listItem, 'bids') && event.gameOrderType == 'Bid') {
          Dom.addClass(listItem, 'uk-active')
        } else if (
          Dom.hasClass(listItem, 'orders') &&
          event.gameOrderType == 'GameOrder'
        ) {
          Dom.addClass(listItem, 'uk-active')
        } else {
          Dom.removeClass(listItem, 'uk-active')
        }
      })
    }

    $('#total').html(event.totalCost)

    super.renderStickySummaryState()
  }

  suggestSavingDefaultCampaign() {
    this.discardDefaultCampaignGamesConfirmation = false

    let confirmMsgBody =
      '<button  class="uk-modal-close-default" type="button" uk-close></button>' +
      '<p><b>Review changes</b></p><p>You have selected games. Do you want to create a campaign and save them?</p>'

    var dialogOpt = {
      labels: { ok: 'Create campaign', cancel: 'Discard games' },
      center: true,
    }

    UIkit.modal
      .confirm(confirmMsgBody, dialogOpt)
      .then(
        $.proxy(this.onSaveCampaignDialogConfirm, this),
        $.proxy(this.onSaveCampaignDialogClose, this)
      )

    $('.uk-modal-dialog button.uk-modal-close').click(
      $.proxy(this.onDiscardDefaultCampaignGames, this)
    )
  }

  submitCampaign() {
    super.submitCampaign()
  }
}
