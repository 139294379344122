import { Controller } from 'stimulus'
import FormSubmissionObserver from '../form/submissionObserver'
import { _ } from 'underscore'
import Ajax from '../http/ajax'
import Dom from '../dom/manipulator'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [
    'approveForm',
    'rejectForm',
    'packageAssetsUpdateForm',
    'counterBid',
    'counterBidInput',
    'bidRequiredMessage',
    'counterBidPop',
  ]

  connect() {
    if (this.hasApproveFormTarget) {
      this.approveFormTarget.addEventListener('ajax:success', (event) => {
        this.handlePackageOrder(event.detail[0])
      })
    }
    this.hideToolTipCallBack = this.hideToolTip.bind(this)
    this.handle_form_events()
  }

  conformPackage()
  {
    var id = this.approveFormTarget.querySelector('button').id
    var team_id = this.approveFormTarget.querySelectorAll('input')[1].value

    Ajax.get(
      'seller_package_bids/'+id+'/package_bid_approve?_method=put& team_id='+team_id,
      success =>{
        if(success.package_flag)
        {
           $('#counterOffer')[0].innerHTML = success.html
          var counter_id = $('#countersCount')[0]
          var counter = parseInt(counter_id.innerText)
          counter_id.innerText = counter -1
        }
        else{
          $('#extramints')[0].innerHTML = success.packageAssetHandlerModalHtml
          UIkit.modal('#package-assets-handler-modal').toggle()
        }   
      },
      err =>{
        console.log('error',err)
      }
    )
  }
 
  rejectPackage()
  {
    var id = this.rejectFormTarget.querySelector('button').id
    var team_id = this.rejectFormTarget.querySelectorAll('input')[1].value

    Ajax.get(
      'seller_package_bids/'+id+'/package_bid_reject?_method=put& team_id='+team_id,
      success =>{
        $('#counterOffer')[0].innerHTML = success.html
        var counter_id = $('#countersCount')[0]
        var counter = parseInt(counter_id.innerText)
        counter_id.innerText = counter -1
      },
      err =>{
        console.log('error',err)
      }
    )

  }

  showCounterBidForm() {
    Dom.show(this.counterBidPopTarget)
    UIkit.util
      .animate(this.counterBidPopTarget, 'uk-animation-fade')
      .then(() => {
        this.counterBidInputTarget.focus()
        document.body.addEventListener('click', this.hideToolTipCallBack)
      })
  }

  disconnect() {
    document.body.removeEventListener('click', this.hideToolTipCallBack)
  }

  hideToolTip(event) {
    // Hide the tooltip when click outside the tooltip.
    if (
      this.hasCounterBidPopTarget &&
      !event.target.closest('.stads-small-tooltip')
    ) {
      Dom.hide(this.counterBidPopTarget)
      document.body.removeEventListener('click', this.hideToolTipCallBack)
    }
  }

  handle_form_events() {
    if (this.hasApproveFormTarget) {
      // only relevant for pending
      new FormSubmissionObserver().bind(this.approveFormTarget, () => {
        const pendingCountElem = document.getElementById('pendingCount')
        Dom.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
      })
    }

    if (this.hasRejectFormTarget) {
      new FormSubmissionObserver().bind(this.rejectFormTarget, (event) => {
        const pendingCountElem = document.getElementById('pendingCount')
        Dom.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
        Dom.html($('#declinedList'), event.detail[0].rejectedItem)
      })
    }

    if (this.hasCounterBidTarget) {
      new FormSubmissionObserver().bind(this.counterBidTarget, () => {
        const pendingCountElem = document.getElementById('pendingCount')
        Dom.html(pendingCountElem, +pendingCountElem.innerHTML - 1)
      })
    }
  }

  handlePackageOrder(response) {
    if (!_.isUndefined(response.packageLockedHandlerModalHtml)) {
      Dom.html(
        this.assetsHandlerModalContainer,
        response.packageLockedHandlerModalHtml
      )
      UIkit.modal(this.lockedPackageModal).show()
    } else if (!_.isUndefined(response.packageAssetHandlerModalHtml)) {
      Dom.html(
        this.assetsHandlerModalContainer,
        response.packageAssetHandlerModalHtml
      )
      UIkit.modal(this.assetsHandlerModal).show()
    } else if (!_.isUndefined(response.noMorePackagesAvailable)) {
      UIkit.modal(this.noMorePackageModal).show()
    }
  }

  updatePackageAssets() {
    var data = new FormData(this.packageAssetsUpdateFormTarget)
    var url = this.packageAssetsUpdateFormTarget.action
    Ajax.post(
      url,
      data,
      (success) => {
        console.log(success)
        UIkit.modal(this.assetsHandlerModal).hide()
        this.assetsHandlerModal.innerHTML = ''
      },
      (err) => {
        console.log(err)
      },
      this
    )
  }

  get assetsHandlerModalContainer() {
    return document.querySelector('#package-assets-handler-modal-container')
  }

  get assetsHandlerModal() {
    return document.querySelector('#package-assets-handler-modal')
  }

  get noMorePackageModal() {
    return document.querySelector('#no-more-packages-modal')
  }

  get lockedPackageModal() {
    return document.querySelector('#locked-packages-modal')
  }
}
