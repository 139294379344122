import { Controller } from 'stimulus'
import { _ } from 'underscore'
import Dom from '../dom/manipulator'
import Rails from 'rails-ujs'

export default class extends Controller {
  connect() {
    Rails.delegate(
      this.element,
      'form[data-target="seller-package-order.approveForm"]',
      'ajax:success',
      (event) => {
        this.packageOrderHandled(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="seller-package-order.rejectForm"]',
      'ajax:success',
      (event) => {
        this.packageOrderHandled(event.detail[0])
      }
    )
  }

  packageOrderHandled(event) {
    const packageRow = Dom.find('#packageRow' + event.packageId)
    if (event.noMoreOrders == true) {
      Dom.remove(packageRow)
    } else {
      Dom.replace(packageRow, event.packageView)
    }

    if (event.emptyState) {
      this.targets.find('orderSection').innerHTML = event.emptyState
    } else {
      if (this.targets.find('bundleOrders') && event.noBundles) {
        Dom.remove(this.targets.find('bundleOrders'))
      }
      if (this.targets.find('pendingOrdersSection') && event.noPendingOrders) {
        Dom.remove(this.targets.find('pendingOrdersSection'))
      } else if (
        this.targets.find('packageOrders') &&
        event.noPendingPackageOrders
      ) {
        this.targets.find('packageOrders').innerHTML =
          event.noPendingPackageOrders
      }

      if (this.targets.find('pendingBidsSection') && event.noPendingBids) {
        Dom.remove(this.targets.find('pendingBidsSection'))
      } else if (
        this.targets.find('packageBids') &&
        event.noPendingPackageBids
      ) {
        this.targets.find('packageBids').innerHTML = event.noPendingPackageBids
      }
    }
    if (_.isString(event.sellerStats)) {
      this.targets.find('statsSection').innerHTML = event.sellerStats
    }
  }
}
