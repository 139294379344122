import { Controller } from 'stimulus'
import UIkit from 'uikit'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'

export default class extends Controller {
  beforePageLeaveHandler = (event) => this.beforePageLeave(event)
  discardDefaultCampaignGamesConfirmation = false

  static targets = [
    'totalBookedMinutes',
    'totalBudget',
    'totalBookedPackages',
    'isDefaultNewCampaign',
    'orderListBox',
    'totalBookingPrice',
    'packageOrderList',
    'packageBidList',
    'packagesPageLink',
    'gamesPageLink',
    'campaignSaveModal',
    'campaignSaveForm',
    'campaignNameInput',
    'saveCampaignButton',
    'campaignCreateSpinner',
    'campaignId',
  ]

  connect() {
    this.destinationUrl = ''

    if (this.hasTotalBookedMinutesTarget) {
      this.totalBookedMinutes = parseInt(this.totalBookedMinutesTarget.value)
    }
    if (this.hasTotalBudgetTarget) {
      this.totalBudget = parseInt(this.totalBudgetTarget.value)
    }
    if (this.hasTotalBookedPackagesTarget) {
      this.totalBookedPackages = parseInt(this.totalBookedPackagesTarget.value)
    }
    if (this.hasPackagesPageLinkTarget) {
      this.packagesPageLink = this.packagesPageLinkTarget.value
    }
    if (this.hasGamesPageLinkTarget) {
      this.gamesPageLink = this.gamesPageLinkTarget.value
    }
  }

  onContinueToCampaignClick() {
    Turbolinks.visit(this.campaignPath)
  }

  loadCampaignSummary() {
    let controllerName = ''
    if (!this.hasPackagesPageLinkTarget) {
      controllerName = 'packages-page'
    }
    else if (!this.hasGamesPageLinkTarget) {
      controllerName = 'games-page'
    }

    if (this.hasCampaignIdTarget) {
      Ajax.get(
        `/campaigns/${this.campaignIdTarget.value}/campaign_summary?controller_name=${controllerName}`,
        (response) => {
          document.getElementById('campaignSummary').innerHTML = response.html
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
  }

  renderStickySummaryState() {
    //todo: use stimulus and dom instead of jquery
    if (
      this.totalBookedMinutes > 0 ||
      this.totalBookedPackages > 0 ||
      this.totalBudget > 0
    ) {
      $('#no-minutes-text').hide()
      $('#total').show()
      this.continueAction.disabled = false

      if (this.subtotalSummary) {
        if (this.hasOrders || this.hasPackageOrder) {
          Dom.hide(this.emptyOrderState)
        } else {
          Dom.show(this.emptyOrderState)
        }
        if (this.hasBids || this.hasPackageBid) {
          Dom.hide(this.emptyBidState)
        } else {
          Dom.show(this.emptyBidState)
        }

        Dom.show(this.orderListBoxTarget)
        Dom.show(this.subtotalSummary)
      }
    } else {
      $('#total').hide()
      $('#no-minutes-text').show()
      if (this.hasContinueActionTarget)
        this.continueAction.disabled = this.isDefaultNewCampaign
      if (this.subtotalSummary) {
        if (!(this.hasOrders || this.hasPackageOrder)) {
          Dom.show(this.emptyOrderState)
        }
        if (!(this.hasBids || this.hasPackageBid)) {
          Dom.show(this.emptyBidState)
        }
      }
    }
  }

  beforePageLeave(event) {
    this.stopListenToPageLeave()

    this.destinationUrl = event.data.url

    if (
      this.isDefaultCampaignWithUnsavedBookedMinutes() &&
      !this.isNavigatingToSaveCampaign()
    ) {
      event.preventDefault()
      this.suspendedNavigationUrl = this.destinationUrl
      this.suggestSavingDefaultCampaign()
    }
  }

  listenToPageLeave() {
    document.addEventListener(
      'turbolinks:before-visit',
      this.beforePageLeaveHandler
    )
  }

  stopListenToPageLeave() {
    document.removeEventListener(
      'turbolinks:before-visit',
      this.beforePageLeaveHandler
    )
  }

  onDiscardDefaultCampaignGames() {
    this.discardDefaultCampaignGamesConfirmation = true
  }

  onSaveCampaignDialogClose() {
    if (this.discardDefaultCampaignGamesConfirmation) {
      Turbolinks.visit(this.suspendedNavigationUrl)
      return
    }

    this.listenToPageLeave()
  }

  onSaveCampaignDialogConfirm() {
    if (this.isNavigatingToPackagesPage() || this.isNavigatingToGamesPage()) {
      UIkit.modal(this.campaignSaveModalTarget).show()
    } else {
      UIkit.modal($('#createCampaignModal')).show()
    }
  }

  submitCampaign() {
    let formData = new FormData(this.campaignSaveFormTarget)
    Ajax.post(
      this.campaignSaveFormTarget.action,
      formData,
      (response) => {
        Dom.hide(this.campaignCreateSpinner)
        console.log(response)
      },
      (err) => {
        Dom.hide(this.campaignCreateSpinner)
        console.log(err)
      },
      this
    )
  }

  onCampaignNameChange() {
    if (this.campaignNameInputTarget.value == '') {
      Dom.disable(this.saveCampaignButtonTarget)
    } else {
      Dom.enable(this.saveCampaignButtonTarget)
    }
  }

  isNavigatingToSaveCampaign() {
    return this.destinationUrl.endsWith(this.continueToCampaignLink)
  }

  isNavigatingToPackagesPage() {
    return this.destinationUrl.endsWith(this.packagesPageLink)
  }

  isNavigatingToGamesPage() {
    return this.destinationUrl.endsWith(this.gamesPageLink)
  }

  isDefaultCampaignWithUnsavedBookedMinutes() {
    return (
      (this.isDefaultNewCampaign && this.totalBookedMinutes > 0) ||
      (this.isDefaultNewCampaign && this.totalBookedPackages > 0)
    )
  }

  get campaignPath() {
    return this.targets.find('continueToCampaignLink').value
  }

  get isDefaultNewCampaign() {
    if (this.hasIsDefaultNewCampaignTarget)
      return this.isDefaultNewCampaignTarget.value == 'true'
    else return false
  }

  get campaignSaveModal() {
    return document.querySelector('#campaignSaveModal')
  }

  get orderBoxListItems() {
    return this.orderListBoxTarget.querySelectorAll('li')
  }

  get hasOrders() {
    return this.orderList.hasChildNodes()
  }

  get hasBids() {
    return this.bidList.hasChildNodes()
  }

  get hasPackageOrder() {
    return this.packageOrderList.hasChildNodes()
  }

  get hasPackageBid() {
    return this.packageBidList.hasChildNodes()
  }
  get total() {
    return this.targets.find('total')
  }

  get continueAction() {
    return this.targets.find('continueAction')
  }

  get emptyOrderState() {
    return this.targets.find('emptyOrderState')
  }

  get emptyBidState() {
    return this.targets.find('emptyBidState')
  }

  get subtotalSummary() {
    return this.targets.find('subtotalSummary')
  }

  get noMinutesText() {
    return this.targets.find('noMinutesText')
  }

  get orderList() {
    return this.targets.find('orderList')
  }

  get bidList() {
    return this.targets.find('bidList')
  }
  get packageOrderList() {
    return this.targets.find('packageOrderList')
  }

  get packageBidList() {
    return this.targets.find('packageBidList')
  }
}
