import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['status'];

  connect() {
    console.log("yesyes")

    this.subscription = App.cable.subscriptions.create(
      {
        channel: "TranscodeStatusChannel",
        room: this.data.get("gameid"),
      },
      {
        connected: this._cableConnected.bind(this),
        disconnected: this._cableDisconnected.bind(this),
        received: this._cableReceived.bind(this),
      }
    );
  }

  _cableConnected() {
    console.log('_cableConnected')
  }

  _cableDisconnected() {
    console.log('_cableDisconnected')
  }

  _cableReceived(data) {
    console.log('_cableReceived')
    this.statusTarget.innerHTML = data.partial;
  }

}