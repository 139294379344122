import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import DOM from '../dom/manipulator'

export default class extends Controller {
  static targets = ['subtotal', 'ordersTitle', 'bidsTitle']
  connect() {
    this.bookNowUrl = this.targets.find('bookNowUrl').value
    this.hasGames = this.targets.find('hasGames').value
    this.hasOrders = this.targets.find('hasOrders').value

    if (this.hasOrders == 'false') {
      this.disableBookNowActions()
    }

    Rails.delegate(
      this.element,
      "form[data-target='game-order.form']",
      'ajax:success',
      (event) => {
        this.onGameBookingUpdatedEvent(event.detail[0])
      }
    )

    $('body').bind(
      'media-upload:start',
      $.proxy(this.disableDraftActions, this)
    )
    $('body').bind('media-upload:error', $.proxy(this.enableDraftActions, this))
    $('body').bind('media-upload:end', $.proxy(this.enableDraftActions, this))
  }

  enableDraftActions() {
    $('#edit-action').removeClass('disabled-secondary-link-button')
    $('#addGamesPrompt .uk-button').removeClass('disabled-primary-link-button')
    $('.add-game-action-secondary').removeClass(
      'disabled-secondary-link-button'
    )

    if (this.hasOrders) {
      $('.book-now').prop('disabled', false)
    }
  }

  disableDraftActions() {
    $('.book-now').prop('disabled', false)
    $('#edit-action').addClass('disabled-secondary-link-button')
    $('#addGamesPrompt .uk-button').addClass('disabled-primary-link-button')
    $('.add-game-action-secondary').addClass('disabled-secondary-link-button')

    this.disableBookNowActions()
  }

  onGameBookingUpdatedEvent(event) {
    const orderMinutes = event.orderMinutes
    const gameOrderId =
      event.gameOrderId == null ? event.deletedGameOrderId : event.gameOrderId
    const totalMinutes = event.totalMinutes
    const hasBids = event.hasBids
    const hasOrders = event.hasOrders

    this.renderBookingUpdate(
      orderMinutes,
      gameOrderId,
      totalMinutes,
      hasBids,
      hasOrders
    )

    $('#detailsFooter').replaceWith(event.detailsScreenFooter)
    this.subtotalTarget.innerText = event.subtotalCost
    Turbolinks.clearCache()
  }

  renderBookingUpdate(
    orderMinutes,
    gameOrderId,
    totalMinutes,
    hasBids,
    hasOrders
  ) {
    if (orderMinutes == 0) {
      $('#gameRow' + gameOrderId).hide()
      $('#gameDivider' + gameOrderId).hide()

      this.removeTrailingGameRowDivider()
    }

    if (totalMinutes == 0) {
      this.hasGames = false
      this.renderNoGames()
    }

    if (!hasOrders && this.hasOrdersTitleTarget) {
      DOM.remove(this.ordersTitleTarget)
    }

    if (!hasBids && this.hasBidsTitleTarget) {
      DOM.remove(this.bidsTitleTarget)
    }
  }

  removeTrailingGameRowDivider() {
    let lastVisibleGameRowElement = $('#games-list > .game-row:visible:last')
    let lastVisibleRowDividerElement = $(
      '#games-list > hr.thin-row-divider:visible:last'
    )

    if ($(lastVisibleRowDividerElement).length == 0) {
      return
    }

    let lastVisibleDividerTop =
      lastVisibleRowDividerElement.position().top -
      lastVisibleRowDividerElement.height()

    let lastVisibleGameRowElementTop =
      lastVisibleGameRowElement.position().top -
      lastVisibleGameRowElement.height()

    if (lastVisibleDividerTop > lastVisibleGameRowElementTop) {
      $(lastVisibleRowDividerElement).hide()
    }
  }

  renderNoGames() {
    $('.footer').hide()
    $('#games-list').hide()
    $('#addGamesPrompt').show()

    this.disableBookNowActions()
  }

  disableBookNowActions() {
    $('.book-now').prop('disabled', true)
  }

  bookNow() {
    Turbolinks.visit(this.bookNowUrl)
  }

  get form() {
    return this.targets.find('form')
  }
}
