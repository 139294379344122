import { Controller } from 'stimulus'
import { _ } from 'underscore'
import Dom from '../dom/manipulator'
import Rails from 'rails-ujs'

export default class extends Controller {
  connect() {
    Rails.delegate(
      this.element,
      'form[data-target="seller-game-order.approveForm"]',
      'ajax:success',
      (event) => {
        this.gameOrderHandled(event.detail[0])
      }
    )

    Rails.delegate(
      this.element,
      'form[data-target="seller-game-order.rejectForm"]',
      'ajax:success',
      (event) => {
        this.gameOrderHandled(event.detail[0])
      }
    )
  }

  gameOrderHandled(event) {
    const gameRow = Dom.find('#gameRow' + event.gameId)
    if (event.noMoreOrders == true)
    {
      Dom.remove(gameRow)
    } else
    {
      Dom.replace(gameRow, event.gameView)
    }

    if (event.emptyState)
    {
      this.targets.find('orderSection').innerHTML = event.emptyState
    } else
    {
      if (this.targets.find('pendingOrdersSection') && event.noPendingOrders)
      {
        this.targets.find('pendingOrdersSection').innerHTML = event.noPendingGameOrders
      }

      if (this.targets.find('pendingBidsSection') && event.noPendingBids)
      {
        this.targets.find('pendingBidsSection').innerHTML = event.noPendingGameBids
      }
    }
    if (_.isString(event.sellerStats))
    {
      this.targets.find('statsSection').innerHTML = event.sellerStats
    }
  }
}
