/* eslint-disable indent */
import {Controller} from  'stimulus'
import Ajax from '../http/ajax'

export default class extends Controller {
	static targets = [
		'currencyValue',
		]

	connect(){ }

	change_currency(event){	
		var target = event.target
		var htmlCollection = target.getElementsByTagName('span')
		var currencyValue =''
		if(htmlCollection.length > 0)
		{
			currencyValue = htmlCollection[0].getAttribute('value')
		}else{
			currencyValue = target.getAttribute('value')
		}
		Ajax.get(
			'/buyer/currencychange?currency_code='+ currencyValue,
			(response) => { location.reload() },
			(error) => { console.log(error) }
		)
	}
}