import GameAssetBaseController from './game_assets_base_controller'
import _ from 'underscore'
import Dom from '../dom/manipulator'
import Ajax from '../http/ajax'
import UIkit from 'uikit'

export default class extends GameAssetBaseController {
  static targets = [
    'modal',
    'feed',
    'game',
    'gameList',
    'gamesText',
    'packageSuccessModal',
    'form',
    'nextButton',
    'createButton',
    'createAnotherBundle',
    'teamInput',
    'step1',
    'step2',
    'selectPrice',
    'selectMatch',
    'paymentMethod',
    'bulkSize',
    'minutes',
    'currentTeam',
    'backButton',
  ]

  connect() {
    this.formData = {}
    this.feedsAndGamesSelection = {}
    this.selectedTeamId = parseInt(
      this.teamInputTarget.getAttribute('data-team-id')
    )
    this.changeBadgeColor()
  }

  selectTeam(event) {
    this.selectedTeamId = parseInt(
      event.currentTarget.getAttribute('data-team-id')
    )
    this.changeBadgeColor()
  }
  // Todo: create a getter to get multiple targets
  selectFeed(event) {
    _.each(this.targets.findAll(`feed${this.selectedTeamId}`), (feed) => {
      if (!feed.isSameNode(event.currentTarget)) {
        this.setCheckbox(feed, false)
      }
    })

    let newValue = this.checkbox(event.currentTarget).checked
    this.setCheckbox(event.currentTarget, newValue)
    this.setTeamData(newValue)
  }

  selectGame() {
    if (this.selectedTeamId in this.feedsAndGamesSelection)
      this.setTeamData(
        this.feedsAndGamesSelection[this.selectedTeamId]['feedSelected']
      )
    else this.setTeamData(false)
  }

  setGames() {
    this.validateSelections()
    if (
      this.selectedGames > 0 ||
      this.selectedTeamId in this.feedsAndGamesSelection
    ) {
      Dom.removeClass(
        this.targets.find('gamesText' + this.selectedTeamId),
        'uk-invisible'
      )
      this.targets.find(
        'gamesText' + this.selectedTeamId
      ).innerHTML = this.selectedGames
    } else this.removeBadge()
  }

  validateSelections() {
    let validated = true
    for (var key in this.feedsAndGamesSelection) {
      if (
        !this.feedsAndGamesSelection[key]['feedSelected'] ||
        this.feedsAndGamesSelection[key]['gamesCount'] < 1
      )
        validated = false
    }
    if (validated && this.totalGamesSelected > 1)
      Dom.removeClass(this.nextButtonTarget, 'uk-disabled')
    else Dom.addClass(this.nextButtonTarget, 'uk-disabled')
  }

  changeBadgeColor() {
    this.resetBadgeColor()
    Dom.addClass(
      this.targets.find('gamesText' + this.selectedTeamId),
      'uk-background-primary'
    )
    Dom.removeClass(
      this.targets.find('gamesText' + this.selectedTeamId),
      'uk-text-muted'
    )
  }

  setTeamData(feedSelection) {
    if (feedSelection || this.selectedGames > 0) {
      this.feedsAndGamesSelection[this.selectedTeamId] = {
        feedSelected: feedSelection,
        gamesCount: this.selectedGames,
      }
    } else {
      delete this.feedsAndGamesSelection[this.selectedTeamId]
      this.removeBadge()
    }
    this.setGames()
  }

  removeBadge() {
    Dom.addClass(
      this.targets.find('gamesText' + this.selectedTeamId),
      'uk-invisible'
    )
    this.targets.find('gamesText' + this.selectedTeamId).innerHTML = ''
  }

  resetBadgeColor() {
    for (var key in this.feedsAndGamesSelection) {
      Dom.removeClass(
        this.targets.find('gamesText' + key),
        'uk-background-primary'
      )
      Dom.addClass(this.targets.find('gamesText' + key), 'uk-text-muted')
    }
  }

  goToGamesSelection() {
    Dom.show(this.step1Target)
    Dom.remove(this.step2Target)
    Dom.show(this.nextButtonTarget)

    _.each(
      [
        this.createButtonTarget,
        this.createAnotherBundleTarget,
        this.backButtonTarget,
      ],
      (node) => {
        Dom.addClass(node, 'uk-invisible')
      }
    )

    Dom.toggleClass(this.selectPriceTarget, 'uk-text-secondary')
    Dom.toggleClass(this.selectMatchTarget, 'uk-text-muted')
    Dom.addClass(this.selectMatchTarget, 'uk-text-secondary')
  }

  getPricingHtml() {
    Dom.show(this.spinner)
    this.combineFormData()
    Ajax.post(
      `packages/pricing_details?team_id=${this.currentTeamTarget.value}`,
      this.combineData,
      (response) => {
        Dom.hide(this.spinner)
        if (
          !_.isUndefined(response.errors) &&
          response.errors.no_package_available
        ) {
          UIkit.modal(this.packageNoticeModal).show()
        } else {
          Dom.removeClass(this.backButtonTarget, 'uk-invisible')
          Dom.hide(this.step1Target)
          Dom.hide(this.nextButtonTarget)
          Dom.removeClass(this.createButtonTarget, 'uk-invisible')
          Dom.removeClass(this.createAnotherBundleTarget, 'uk-invisible')
          Dom.removeClass(
            this.createAnotherBundleTarget.closest('div'),
            'uk-width-1-3'
          )
          Dom.addClass(
            this.createAnotherBundleTarget.closest('div'),
            'uk-width-1-2'
          )
          Dom.toggleClass(this.selectPriceTarget, 'uk-text-secondary')
          Dom.toggleClass(this.selectMatchTarget, 'uk-text-secondary')
          Dom.addClass(this.selectMatchTarget, 'uk-text-muted')
          Dom.append(response.html, this.formTarget)
        }
      },
      (err) => {
        Dom.hide(this.spinner)
        console.log(err)
      },
      this
    )
  }

  submit(event) {
    let submitBtn = event.currentTarget
    if (this.nameMissing()) {
      Dom.addClass(this.packageName, 'error')
      Dom.show(this.packageErrorMsg)
      return
    }
    Dom.disable(submitBtn)
    Dom.show(this.spinner)
    this.combineFormData()
    this.appendCreateAnotherBundleData()
    UIkit.switcher(this.listingsSwitcher).show(0)

    Ajax.post(
      `packages/?team_id=${this.currentTeamTarget.value}`,
      this.combineData,
      (response) => {
        Dom.hide(this.spinner)
        if (!_.isUndefined(response.data)) {
          if (response.data.create_another_bundle) {
            this.showModal(response.modal_html)
            UIkit.modal(this.packageSuccessModalTarget).show()
          }
        } else if (!_.isUndefined(response.errors)) {
          if (response.errors.package_exists) {
            UIkit.modal.dialog(
              '<p class="uk-modal-body">Package already exists!</p>'
            )
          }
        }
        Dom.enable(submitBtn)
        Dom.html(
          document.querySelector('#packages-list'),
          response.listing_html
        )
      },
      (err) => {
        Dom.enable(submitBtn)
        Dom.hide(this.spinner)
        Dom.html(document.querySelector('.uk-modal-full'), '')
        console.log(err)
      },
      this
    )
  }

  nameMissing() {
    return this.packageName.value.length == 0
  }

  changePaymentMethod() {
    this.handlePriceElements()
    this.handleCreateButtonState()
  }

  bulkSizeChanged() {
    const bulkSize = parseInt(this.bulkSizeTarget.value)
    const elements = document.querySelectorAll('.min-text')
    _.each(elements, (element) => {
      Dom.html(element, `&nbsp(${bulkSize}min. per game)`)
    })

    let minutesInputOptions = this.minutesTarget.options

    this.updateMinutesOptions(bulkSize, minutesInputOptions)
    this.selectFirstIndex(this.minutesTarget, minutesInputOptions[0])
    this.handlePriceElements()
  }

  updateMinutesOptions(bulkSize, optionList) {
    let limit = Math.floor(optionList.length / bulkSize)
    _.each(optionList, (option) => {
      if (parseInt(option.value) <= limit) {
        Dom.show(option)
      } else {
        Dom.hide(option)
      }
    })
  }

  selectFirstIndex(optionList, firstIndex) {
    optionList.selectedIndex = firstIndex
  }

  handlePriceElements() {
    const paymentMethod = this.paymentMethodTarget.value
    const bulkSize = this.bulkSizeTarget.value

    const hiddenElements = document.querySelectorAll(
      `.both:not(.${paymentMethod})`
    )
    const showElements = document.querySelectorAll(`.${paymentMethod}`)
    _.each(hiddenElements, (element) => {
      Dom.hide(element)
      if (Dom.hasClass(element, paymentMethod)) {
        element.querySelector('input').value = ''
      }
    })
    _.each(showElements, (element) => {
      Dom.show(element)
      let input = element.querySelector('input[type="number"]')
      input.value = input.getAttribute('value') * bulkSize
    })
  }

  handleCreateButtonState() {
    const inputsContainers = document.querySelectorAll('.both:not([hidden])')
    const createButton = document.getElementById('createButton')
    const blankInput = _.find(inputsContainers, (element) => {
      var value = parseInt(element.querySelector('input').value)
      return !value || value < 0
    })
    if (blankInput) {
      createButton.disabled = true
    } else {
      createButton.disabled = false
    }
  }

  combineFormData() {
    this.combineData = new FormData(this.formTarget)
  }

  appendCreateAnotherBundleData() {
    let checkbox = this.checkbox(this.createAnotherBundleTarget)
    if (checkbox.checked) {
      this.combineData.append(checkbox.name, checkbox.value)
    }
  }

  hideNameError() {
    Dom.removeClass(this.packageName, 'error')
    Dom.hide(this.packageErrorMsg)
  }

  get listingsSwitcher() {
    return document.querySelector('#package-listings-switcher')
  }

  get selectedGames() {
    return this.findCheckedCells(
      this.targets.findAll('game' + this.selectedTeamId)
    ).length
  }

  get totalGamesSelected() {
    var count = 0
    for (var key in this.feedsAndGamesSelection) {
      count += this.feedsAndGamesSelection[key]['gamesCount']
    }
    return count
  }

  get selectedFeedName() {
    return this.findCheckedCell(
      this.targets.findAll('feed' + this.selectedTeamId)
    ).textContent
  }
  get packageNoticeModal() {
    return document.querySelector('#package-notice-modal')
  }

  get packageName() {
    return document.querySelector('#package-name').querySelector('input')
  }

  get packageErrorMsg() {
    return document.querySelector('#package-name').querySelector('.error-msg')
  }
}
